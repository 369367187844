import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Footer from '../Components/Footer'
import Form from '../Components/Form';
import Navbartwo from '../Components/Navbartwo'
import sadeyes from '../sadeyes.png';
import MetaTags from '../Components/Metatags';
import { numberWithCommas } from '../helper/numberFormat';

const Cartscreen = ({ cart }) => {
    const two = JSON.parse(localStorage.getItem('catrs'))
    JSON.parse(localStorage.getItem('carts'))

    const [set, setSet] = useState(two)
    const [checkout, setCheckout] = useState(false)

    const remove = (id,) => {
        let newset = set.filter((pep) => pep.id !== id)
        localStorage.setItem('catrs', JSON.stringify(newset))
        setSet([...newset])
    }

    const checkouts = () => {
        setCheckout(!checkout)
        window.scrollTo(0, 0)
    }
    let a = set.reduce((a, c) => a + c.price * c.qty, 0)
    localStorage.setItem('price', Number(a))


    return (
        <div className='cartscreen'>
               {/* <MetaTags title={"Cart"} /> */}
            <Navbartwo closeForm={setCheckout}></Navbartwo>
            {
                !checkout && <div className='carttts'>
                    {two.length === 0 ? (
                        <div className='cartempty' style={{ height: '', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <div  >
                                <h1 style={{ textAlign: 'center', paddingBottom: '20px' }}>Your Items ({two.length})</h1>
                                <h2 className="emptyh1" style={{ paddingBottom: '20px' }}>Cart is Empty</h2>
                                <img style={{ width: '80%' }} src={sadeyes} alt="io"></img>
                                <div>
                                    <Link to='/shop' onClick={() => window.scrollTo(0, 0)}>
                                        <button className="buttonempty empty">Go Shopping</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <Link to='/shop' onClick={() => window.scrollTo(0, 0)}><p className="linkback lone">&#8592; Continue Shopping</p></Link>
                            <h1 style={{ textAlign: 'center', paddingBottom: '20px', color: '#f55b1f' }}>Your Items ({two.length})</h1>

                            {
                                two.map((set, index) => (
                                    <div key={set.id} className="cart" style={{ borderBottom: '1px solid black', borderTop: '1px solid black' }}>
                                        <div>
                                            <Link to={`/shop/product/${set.slug}`} onClick={() => window.scrollTo(0, 0)}>
                                                
                                           {set.imageadjustment ? <img src={set.image} className="smallss" alt="merch"></img> : 
                                                <img src={set.image} className="smalls" alt="merch"></img> }
                                                
                                                </Link>
                                            <p style={{ paddingTop: '7px', fontSize: '12px' }}>{set.name}</p>
                                        </div>
                                        {
                                            set.hassize === false ? (
                                                <div style={{ textAlign: 'center', paddingLeft: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} className="cartdesc">
                                                    <p>Qty:  {set.qty}</p>
                                                    <p style={{ paddingTop: '20px', paddingBottom: '20px' }}>Price: ₦ {set.price}</p>
                                                    <button onClick={() => remove(set.id)} className="buttonempty">remove</button>
                                                </div>) : (
                                                <div style={{ textAlign: 'center', paddingLeft: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} className="cartdesc">
                                                    <p>Qty:  {set.qty}</p>
                                                    <p style={{ paddingTop: '20px', paddingBottom: '20px' }}>size: {set.size}</p>
                                                    <p style={{ paddingTop: '0px', paddingBottom: '20px' }}>Price: ₦{numberWithCommas(set.price)}</p>
                                                    <button onClick={() => remove(set.id)} className="buttonempty">remove</button>
                                                </div>
                                            )
                                        }
                                    </div>
                                ))
                            }
                            {

                            }
                            <h2 className='subtotal' style={{ paddingTop: '20px' }}>SubTotal: ₦ {numberWithCommas(set.reduce((a, c) => a + c.price * c.qty, 0))} </h2>
                            <div style={{ textAlign: 'center', padding: '20px' }}>
                                <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                                    <button className="buttonempty" style={{ marginRight: '4px', }}>Update Items</button></Link>
                                <button onClick={() => checkouts()} className="buttonempty" style={{ marginLeft: '4px' }}>Checkout</button>
                            </div>
                        </div>
                    )}
                </div>
            }
            {checkout &&
                <Form closeForm={setCheckout} price={a}></Form>}
            <Footer></Footer>
        </div>
    )
}

export default Cartscreen